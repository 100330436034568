<script setup>
import Section from '@/Components/Section.vue';
import Heading from '@/Components/Heading.vue';
import Progress from '@/Components/Progress.vue';
import Badge from '@/Components/Badge.vue';
import Loader from '@/Components/Loader.vue';
import { chunks } from "@/Components/Composable/Chunk";

import { Swiper, SwiperSlide } from 'swiper/vue';
import { Pagination, Virtual } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import { computed, onBeforeUnmount, onMounted, ref } from "vue";
import { formatPrice } from '@/Components/Composable/Monetize.js';

const props = defineProps( {
    profile: Object,
    interval: Number,
} )

// Set sales list
const sales = ref( [] );

// Load sales when mounting and subscribe to websocket
onMounted( () => {
    loadSales();

    // Interval for every 5 minutes
    setInterval(() => {
        loadSales();
    }, props.interval);

    window.Echo.channel( `dashboard.${props.profile.slug}` ).listen( '.sales', (data) => {
        if (data?.hasChanged) {
            loadSales();
        }
    } );
} )

// Unsubscribe from webhook when unmounting
onBeforeUnmount( () => {
    window.Echo.channel( `dashboard.${props.profile.slug}` ).stopListening( '.sales' );
} )

/**
 * Load sales from API
 */
const loadSales = () => {
    axios.get( `/api/${props.profile.slug}/sales` ).then( (response) => {
        const { data } = response;

        sales.value = data.sales;
    } );
}

// Chunk projects for slider
const salesChunked = computed( () => {
    return chunks( sales.value )
} );
</script>

<template>
    <Section>
        <Heading title="Sales" class="mb-4"/>

        <div class="flex grow" v-if="sales.length > 0">
            <swiper
                :slides-per-view="1"
                :space-between="0"
                :pagination="{ clickable: true }"
                :grabCursor="true"
                :virtual="true"
                :loop="true"
                :modules="[Pagination, Virtual]">
                <swiper-slide
                    v-for="(chunk, index) in salesChunked"
                    :key="index"
                    :virtualIndex="index">
                    <table class="min-w-full">
                        <colgroup>
                            <col class="w-full sm:w-5/12"/>
                            <col class="lg:w-2/12"/>
                            <col class="lg:w-3/12"/>
                            <col class="lg:w-2/12"/>
                        </colgroup>
                        <thead>
                            <tr class="text-xs text-left text-gray-400 font-semibold">
                                <th scope="col">Sale</th>
                                <th scope="col">Potentiële omzet</th>
                                <th scope="col">Voortgang</th>
                                <th scope="col">Medewerker</th>
                            </tr>
                        </thead>
                        <tbody v-if="chunk.length">
                            <tr v-for="(sale, index) in chunk" :key="index">
                                <td class="whitespace-nowrap py-2.5">
                                    <div class="flex flex-col">
                                        <div class="flex items-center mb-0.5">
                                            <h3 class="text-base font-semibold">{{ sale.organisation }}</h3>
                                        </div>
                                        <span class="text-xs text-gray-500 truncate max-w-[430px]">{{ sale.name }}</span>
                                    </div>
                                </td>
                                <td class="whitespace-nowrap py-2.5">
                                    <span class="flex text-xs">{{ formatPrice(sale.expected_revenue, true) }}</span>
                                </td>
                                <td class="whitespace-nowrap py-2.5">
                                    <Progress
                                        :current="sale.progress.chance"
                                        :goal="100"
                                        :indicator="sale.progress.indicator"
                                        :large="false"
                                        :labels="false"
                                        :color="sale.progress.color"
                                        :progress-label="sale.progress.label"
                                        :center-label="true"
                                    />
                                </td>
                                <td class="whitespace-nowrap py-2.5 pl-5">
                                    <img class="w-9 h-9 rounded-full overflow-hidden object-cover" width="30" height="30" :src="sale.employee.avatar" :alt="sale.employee.name"/>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </swiper-slide>
            </swiper>
        </div>
        <Loader v-else/>
    </Section>
</template>
